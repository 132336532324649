import React, { Component } from 'react';
import Livelablottie from "../../lottie/livelablottie";
import Teacher from "../../../images/teacher.jpg";
import LivedashA from "../../../images/screenshots/livedash-a.jpg";
import LivedashB from "../../../images/screenshots/livedash-b.jpg";
import LivedashC from "../../../images/screenshots/livedash-c.jpg";
import Students from "../../../images/screenshots/lms-screen.jpg";
import Uiscreen from "../../../images/screenshots/dashboard5.jpg";
import Vidsourcelab from "../../../assets/vid/classroom.mp4";
import Vidsourcelabgif from "../../../assets/vid/liveview.gif";


const styles = {
	studentback: {
		backgroundImage: `url(${Students})`
	},
	tutorsback: {
		backgroundImage: `url(${Teacher})`
    },
    vidlabgif: {
		backgroundImage: `url(${Vidsourcelabgif})`
    },
    uiscreenback: {
		backgroundImage: `url(${Uiscreen})`
	}
};


class Livelabinternalbanner extends Component {


    constructor(props) {
        super(props);
        this.state = {
            vidsrca: ''
        };
      }

      swapVidSource = () => {
        this.setState({
            vidsrca: Vidsourcelab
          });
}

playVid = () => {
    if(this.refs.vidRefa){
        this.refs.vidRefa.play();
        }
}

pauseVideo = () => {
    if(this.refs.vidRefa){
    this.refs.vidRefa.pause();
    }
}

componentDidMount = () => {
    this.swapVidSource();
    setTimeout(() => {
        this.playVid();
      }, 1000);
  };

  componentWillUnmount = () => {
      this.pauseVideo();
  };

  

    render() {

        return (
            <div>
                <section className="internalbanner">
	<div className="overfunk"></div>
    <video className="showondesktop" ref="vidRefa" autoplay muted loop id="internalbannervideo" key={this.state.vidsrca}>
<source src={this.state.vidsrca} type="video/mp4"/>
</video>
<div className="showonmobile gifbanger" style={styles.vidlabgif}></div>
		<div className="container">
			<div className="columns is-marginless">
				<div className="column is-half">
					<p className="topsubtitle">Instant Academic Results with LiveView </p>
					<h1>LiveView</h1>
					<p className="undersubtitle">Innovative Assignment Marking in Colleges, Universities, Corporate Training Programs etc. </p>
				</div>
			</div>
		</div>
	</section>


	<section className="under-internalbanner graddown">
		<div className="threeboxes">
			<div className="container">
		<div className="boxcard norise">
                <div className="columns is-marginless">
                <div className="column is-10">
                <h2>Revolutionise <span>your testing workflow</span></h2><hr/>
                <p>Having trouble <strong>keeping up with all of the assignments that need to be corrected</strong>?<br/>
                <br/>Using LiveView, we'll help you boost your correcting speed so you can optimize your time. </p>
                <p>LiveView is an academic service that provides rapid assignment scoring across various subjects.</p>
                </div>
                </div>
</div>
			</div>
            </div>
		</section>
        <section className="under-internalbanner-single graddown">
<div className="columns is-marginless">
<div className="column is-4 is-paddingless">
<figure className="fullimage halfturate backcover" style={styles.uiscreenback}>
<img src={Uiscreen} alt="teacher" className="hideo"/>
</figure>
</div>
<div className="column is-6 is-offset-1 pictext">
<div className="innerbox">
<h3>UI-based reporting for students &amp; tutors</h3>
<p>Student scores are not only available immediately, but reports are available to the student across the entire duration of their academic year - perfect for focusing on results and tracking yearly or course-wide progress.</p>
</div>
</div>
</div>
</section>
<section className="under-internalbanner graddown">
<div className="threeboxes">
<div className="container">
<div className="boxcard norise">
<div className="columns is-marginless studentstutors">
<div className="column is-5 pictext flexpictext leftboxboy">
<h3>Students</h3><hr/>
<p>For students, it provides you an immediate score for your assignments without having to wait.</p><p>The reports are available on-line throughout the year and are immediately and easily accessible for review, particularly when required during study.</p>
</div>
<div className="column is-7 backpic-students" style={styles.studentback}>
</div>
</div>


<div className="columns is-marginless">
<div className="column is-7 backpic-tutors" style={styles.tutorsback}>
</div>
<div className="column is-5 pictext flexpictext rightboxboy">
<h3>Tutors</h3><hr/>
<p>For managers and tutors, this will allow you to assign and view scoring in real time.</p>
<p>It allows you to provide focus on topics that may not be scoring well. It optimizes your time, allowing you to focus on students scores and provide support to those in need.</p>
</div>
</div>
</div>
</div>
</div>
</section>
        <section className="diagram graddown">
  <div className="container">
		<div className="columns is-marginless is-centered">
		<div className="column is-10 has-text-centered">
        <Livelablottie />
		</div>
      </div>
  </div>
</section>


        <section className="testimony">
  <div className="container">
		<div className="columns is-marginless is-centered">
		<div className="column is-12 has-text-centered">
        <div className="columns is-marginless is-centered">
                <div className="column is-4 circlestuff">
                <div className="circleimage">
                <img src={LivedashA} alt="live view"/>
                </div>
                <p>Optimizes tutors' time</p>
                </div>

                <div className="column is-4 circlestuff">
                <div className="circleimage">
                <img src={LivedashB} alt="live view"/>
                </div>
                <p>Year-round test data for students</p>
                </div>

                <div className="column is-4 circlestuff">
                <div className="circleimage">
                <img src={LivedashC} alt="live view"/>
                </div>
                <p>Immediate scoring/results for students</p>
                </div>
        </div>
        </div>
		</div>
      </div>
</section>


            </div>
        );
    }
}

export default Livelabinternalbanner;