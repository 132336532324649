import React from 'react'
import Lottie from 'react-lottie';
import * as animationDataWater from "../../plugins/lottie/livelab.json"
 
export default class Livelablottie extends React.Component {
 
  render() {

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationDataWater.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    };
 
    return <div>
      <Lottie options={defaultOptions} speed={1}/>
    </div>
  }
}